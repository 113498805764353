<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3" v-if="showCardForAdmin">
        <router-link to="/customer">
          <div class="box">
            <div class="d-flex justify-content-between align-items-center">
              <i class="fa fa-users fa-3x"></i>
              <div class="number">{{ this.totalCustomer }}</div>
            </div>
            <h3 class="pt-5">Customers</h3>
          </div>
        </router-link>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3">
        <router-link to="/restaurant">
          <div class="box">
            <div class="d-flex justify-content-between align-items-center">
              <i class="fa fa-hotel fa-3x"></i>
              <div class="number">{{ this.restaurants }}</div>
            </div>
            <h3 class="pt-5">Restaurant</h3>
          </div>
        </router-link>
      </div>

      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3" v-if="showCardForAdmin">
        <router-link to="/users/restaurant">
          <div class="box">
            <div class="d-flex justify-content-between align-items-center">
              <i class="fa fa-users fa-3x"></i>
              <div class="number">{{ this.restaurantUsers }}</div>
            </div>
            <h3 class="pt-5">Restaurant Users</h3>
          </div>
        </router-link>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3">
        <router-link to="/orders">
          <div class="box">
            <div class="d-flex justify-content-between align-items-center">
              <i class="fa fa-shopping-cart fa-3x"></i>
              <div class="number">{{ this.totalOrders }}</div>
            </div>
            <h3 class="pt-5">Orders</h3>
          </div>
        </router-link>
      </div>

      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-3">
        <router-link to="/orders">
          <div class="box">
            <div class="d-flex justify-content-between align-items-center">
              <i class="fa fa-shopping-cart fa-3x"></i>
              <div class="number">{{ this.todayOrders }}</div>
            </div>
            <h3 class="pt-5">Today's Order</h3>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import store from "@/core/services/store/index.js";

export default {
  name: "dashboard",
  data() {
    return {
      restaurants: 0,
      restaurantUsers: 0,
      totalOrders: 0,
      todayOrders: 0,
      totalCustomer: 0,
      showCardForAdmin: false,
    };
  },
  components: {},
  mounted() {
    document.title = "Dashboard";
    if (
      store.state.loginUserType == "superadmin" ||
      store.state.loginUserType == "admin"
    ) {
      this.showCardForAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getRecord();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },

    getRecord() {
      try {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/dashboard/statics", "", {
            headers,
          })
          .then((response) => {
            let object = response.data.data;
            this.totalCustomer = object.customers;
            this.totalOrders = object.orders;
            this.restaurants = object.total_restaurant;
            this.restaurantUsers = object.restaurant_users;
            this.todayOrders = object.today_orders;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>

<style scoped>
.box {
  background: rgb(92, 183, 0);
  color: #000;
  cursor: pointer;
  height: 130px;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.box .fa {
  color: #000;
}
.box:hover {
  background: #000;
  color: #fff;
}
.box:hover .fa {
  color: #fff;
}
.number {
  font-size: 20px;
  font-weight: bold;
}
</style>
